import React from "react"
import {Typography, Grid, Container} from "@material-ui/core"
import {makeStyles} from '@material-ui/core/styles';
import styled from "styled-components";
import {StaticImage} from "gatsby-plugin-image"
import {Link} from "gatsby";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import logo from "../images/logo_e_small.svg"

const StyledGatsbyLink = styled(Link)`
    text-decoration: none;
    color: #8a8e93;
`;

const useContainerStyles = makeStyles({
    root: {
        padding: '140px 0 0 0',
        position: 'relative',
        width: '100%',
        boxSizing: 'border-box',
    }
});

const Footer = () => {
    const xsScreen = useMediaQuery("(min-width:0px) and (max-width:599px)");
    const smallScreen = useMediaQuery("(min-width:600px) and (max-width:959px)");
    const containerClasses = useContainerStyles();

    return (
        <Container className={containerClasses.root} maxWidth={false}>
            <Grid item container justify={"flex-end"}>
                <Grid item container md={10} xs={12} justify={'space-between'} alignItems={'center'}
                      direction={(xsScreen || smallScreen) ? 'column' : 'row'}
                      style={{backgroundColor: '#393e48'}}>
                    <Grid item container md={7} alignItems={'center'}
                          justify={(xsScreen || smallScreen) ? 'center' : 'space-between'}>
                        <Grid item style={{backgroundColor: '#151920', padding: '40px'}} md={4} container justify={'center'}>
                            <img style={{width: '71px', marginBottom: 0}} src={logo} alt={'Logo Image'}/>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography color={'secondary'} style={(xsScreen || smallScreen) ? {
                                textAlign: 'center',
                                marginTop: '20px'
                            } : {textAlign: 'center'}}>
                                <StyledGatsbyLink to={'/'}>
                                    Home
                                </StyledGatsbyLink>
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography color={'secondary'} style={(xsScreen || smallScreen) ? {
                                textAlign: 'center',
                                marginTop: '20px'
                            } : {textAlign: 'center'}}>
                                <StyledGatsbyLink to={'/#brands'}>
                                    Brands
                                </StyledGatsbyLink>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item md={3} container justify={'center'}>
                        <Typography color={'secondary'} style={(xsScreen || smallScreen) ? {padding: '2rem 0'} : null}>
                            Eparia © 2021 All right reserved
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
};

export default Footer;
