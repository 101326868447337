import { Link } from "gatsby"
import { useDispatch } from "react-redux";
import React, {useEffect} from "react"
import { makeStyles } from '@material-ui/styles';
import {Button, Grid, AppBar, Container} from "@material-ui/core"
import useMediaQuery from '@material-ui/core/useMediaQuery';
import styled from 'styled-components'
import Logo from './logo'
import MenuOpen from '@material-ui/icons/MenuOpen';

const StyledGatsbyLink = styled(Link)`
    text-decoration: none;
`;

const useAppBarStyles = makeStyles({
    root: {
        padding: '32px 1.25rem',
        boxShadow: 'none',
        backgroundColor: 'rgba(0,0,0,0)'
    }
});

const useButtonSecondaryStyles = makeStyles({
    root: {
        textTransform: 'none',
        borderRadius: "50px",
        padding: '20px 90px',
        borderWidth: '3px',
        borderStyle: 'solid',
        borderColor: 'rgba(0,0,0,0)',
        '&:hover': {
            textDecoration: 'none',
            borderWidth: '3px',
            borderColor: '#8a8e93',
            borderStyle: 'solid'
        },
        "@media (min-width:0px) and (max-width:1279px)": {
            padding: '20px 20px',
        }
    }
});

const Header = (props) => {
    const dispatch = useDispatch();
    const xsScreen = useMediaQuery("(min-width:0px) and (max-width:599px)");
    const smallScreen = useMediaQuery("(min-width:600px) and (max-width:959px)");
    const appBarClasses = useAppBarStyles();
    const buttonSecondaryClasses = useButtonSecondaryStyles();

    const toggleDrawer = e => {
        dispatch({type: "TOGGLE_DRAWER",
            payload: true
        });
    };

    return (
        <AppBar className={appBarClasses.root} color={'inherit'} position={'static'}>
            <Container maxWidth={'xl'}>
                <Grid container justify={'space-between'}>
                    <Grid container justify={'space-between'} alignItems={'center'}>
                        <Grid item container xs={9} md={2}>
                            <StyledGatsbyLink to={'/'}>
                                <Logo/>
                            </StyledGatsbyLink>
                        </Grid>
                        {(xsScreen || smallScreen) ?
                            <Grid item container xs={3} justify={'flex-end'}>
                                <Button value={true} onClick={toggleDrawer}>
                                    <MenuOpen color={'primary'}/>
                                </Button>
                            </Grid>
                            :
                            <Grid item container xs={8} md={10}>
                                <Grid container item alignItems={'center'} justify={"flex-end"} spacing={4}>
                                    <Grid item>
                                        <StyledGatsbyLink to={'/'}>
                                            <Button className={buttonSecondaryClasses.root} color={'secondary'}>Home</Button>
                                        </StyledGatsbyLink>
                                    </Grid>
                                    <Grid item>
                                        <StyledGatsbyLink to={'/#brands'}>
                                            <Button className={buttonSecondaryClasses.root} color={'secondary'}>Brands</Button>
                                        </StyledGatsbyLink>
                                    </Grid>
                                    <Grid item>
                                        <StyledGatsbyLink to={'/#contact'}>
                                            <Button className={buttonSecondaryClasses.root} color={'secondary'}>Contact</Button>
                                        </StyledGatsbyLink>
                                    </Grid>
                                    {/*<Grid item>*/}
                                    {/*    <Button className={buttonClasses.root} color={'secondary'}>En</Button>*/}
                                    {/*</Grid>*/}
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </Container>
        </AppBar>
    )
};

export default Header
