/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import {Link} from "gatsby"
import {Button, Drawer, Grid, Box} from "@material-ui/core";
import "./layout.css"
import { useSelector, useDispatch} from "react-redux";
import Header from "./header"
import styled from "styled-components";
import SEO from '../components/seo'
import Footer from "./footer";
import {createMuiTheme} from '@material-ui/core/styles';
import {makeStyles, ThemeProvider} from "@material-ui/styles";

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Montserrat',
            '-apple-system',
            'Roboto',
            'Arial',
            'sans-serif',
        ].join(',')
    },
    palette: {
        primary: {
            // light: will be calculated from palette.secondary.main,
            main: '#eeac86',
            // dark: will be calculated from palette.secondary.main,
            // contrastText: will be calculated to contrast with palette.secondary.main
        },
        secondary: {
            // light: '#0066ff',
            main: '#8a8e93',
            // dark: will be calculated from palette.secondary.main,
            // contrastText: '#fff',
        },
        // Used by `getContrastText()` to maximize the contrast between
        // the background and the text.
        contrastThreshold: 3,
        // Used by the functions below to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,
    },
});


const StyledGatsbyLink = styled(Link)`
    text-decoration: none;
`;

const useBoxStyles = makeStyles({
    root: {
        overflowX: 'hidden',
        background: 'linear-gradient(135deg, rgba(22,27,34,1) 1%, rgba(13,17,24,1) 51%, rgba(22,27,34,1) 99%)',
        filter: 'progid:DXImageTransform.Microsoft.gradient(startColorstr="#1f232a",endColorstr="#1f232a",GradientType=1)'
    }
});


const useDrawerStyles = makeStyles({
    root: {
        background: 'linear-gradient(180deg, rgba(31,35,42,1) 20%, rgba(21,25,32,1) 60%, rgba(31,35,42,1) 100%)',
        filter: 'progid:DXImageTransform.Microsoft.gradient(startColorstr="#1f232a",endColorstr="#1f232a",GradientType=1)'
    }
});

const useButtonStyles = makeStyles({
    root: {
        textTransform: 'none',
        borderRadius: "50px",
        padding: '20px 90px',
        borderWidth: '3px',
        borderStyle: 'solid',
        borderColor: 'rgba(0,0,0,0)',
        '&:hover': {
            textDecoration: 'none',
            borderWidth: '3px',
            borderColor: '#8a8e93',
            borderStyle: 'solid'
        }
    }
});

const Layout = ({children}) => {
    const dispatch = useDispatch();
    const boxClasses = useBoxStyles();
    const buttonClasses = useButtonStyles();
    const drawerClasses = useDrawerStyles();
    const {drawer} = useSelector(state => {
        return {
            ...state.interface_reducer
        }
    });

    const closeDrawer = () => {
        dispatch({type: "TOGGLE_DRAWER",
            payload: false
        });
    };

    return (
        <Box className={boxClasses.root}>
            <ThemeProvider theme={theme}>
                <SEO/>
                <Header/>
                {children}
                <Footer/>
                <Drawer anchor={'right'} open={drawer} onClose={closeDrawer} PaperProps={{className: drawerClasses.root}}>
                    <Grid container item alignItems={'center'} spacing={4} justify={'flex-start'} direction={'column'}
                          style={{height: '100vh', padding: '2rem 1rem'}}>
                        <Grid item>
                            <StyledGatsbyLink to={'/'}>
                                <Button variant={'outlined'} color={'secondary'} onClick={closeDrawer}
                                        className={buttonClasses.root}>Home</Button>
                            </StyledGatsbyLink>
                        </Grid>
                        <Grid item>
                            <StyledGatsbyLink to={'/#brands'}>
                                <Button variant={'outlined'} color={'secondary'} onClick={closeDrawer}
                                        className={buttonClasses.root}>Brands</Button>
                            </StyledGatsbyLink>
                        </Grid>

                        <Grid item>
                            <StyledGatsbyLink to={'/#contact'}>
                                <Button variant={'outlined'} color={'secondary'} onClick={closeDrawer}
                                        className={buttonClasses.root}>Contact</Button>
                            </StyledGatsbyLink>
                        </Grid>
                    </Grid>
                </Drawer>
            </ThemeProvider>
        </Box>
    )
};

export default Layout
