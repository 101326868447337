import React, {Fragment} from "react"
import useMediaQuery from '@material-ui/core/useMediaQuery';
import logo from "../images/logo_e_big.svg"

const Logo = () => {
    const smallScreen = useMediaQuery("(min-width:0px) and (max-width:599px)");
    const xsScreen = useMediaQuery("(min-width:0px) and (max-width:599px)");

    return (
        <Fragment>
            {(smallScreen || xsScreen) ?
                <img style={{width: '170px', marginBottom: 0}} src={logo} alt={'Logo Image'}/>
                :
                <img style={{width: '200px', marginBottom: 0}} src={logo} alt={'Logo Image'}/>
            }
        </Fragment>
    )

};

export default Logo;
