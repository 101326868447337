/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import Helmet from "react-helmet"

function SEO({lang, meta}) {

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={'Eparia - Eparia.com - Innovative Ecommerce Brands in One Place'}
            meta={[
                {
                    name: `description`,
                    content: 'Our group will help you outpace the competition digitally. From logistics to product sourcing, Eparia and our group of brands help businesses redefine digital sales, capturing new opportunities and growing exponentially.',
                },
                {
                    property: `og:title`,
                    content: 'Eparia',
                },
                {
                    property: `og:description`,
                    content: 'Our group will help you outpace the competition digitally. From logistics to product sourcing, Eparia and our group of brands help businesses redefine digital sales, capturing new opportunities and growing exponentially.'
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:card`,
                    content: `Innovative Ecommerce Brands in One Place`,
                },
                {
                    name: `twitter:title`,
                    content: 'Eparia',
                },
                {
                    name: `twitter:description`,
                    content: `Our group will help you outpace the competition digitally. From logistics to product sourcing, Eparia and our group of brands help businesses redefine digital sales, capturing new opportunities and growing exponentially.`,
                },
            ].concat(meta)}
        >
            <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&display=swap"
                  rel="stylesheet"/>
        </Helmet>
    )
}

SEO.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
}

export default SEO
